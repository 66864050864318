import React from "react"
import Joi from "joi-browser"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/common/form"
import { login } from "../services/authService"
import { isBrowser } from "../utils/functions"
import { toast } from "react-toastify"
import SpinnerLoading from "../components/common/spinnerLoading"

class Login extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
    isLoggedIn: false,
    loading: false,
  }

  schema = {
    username: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .label("Password"),
  }

  doSubmit = async e => {
    e.preventDefault()
    if (this.state.loading) {
      return
    }
    this.setState({ loading: true }, async () => {
      let errors = this.validate()
      this.setState({ errors: errors || {} })
      if (errors) {
        this.setState({ loading: false })
        toast.error("Sono stati riscontrati degli errori di compilazione")
        return
      }
      if (errors === null) {
        errors = {}
      }

      const { data: formData } = this.state
      if (await login(formData.username, formData.password, errors)) {
        const token =
          isBrowser() && window.localStorage.getItem("token")
            ? window.localStorage.getItem("token")
            : null

        if (token) {
          window.location = "/ordine"
        } else {
          this.setState({ errors, loading: false })
        }
      } else {
        errors.username = "Utente non riconosciuto"
        this.setState({ errors, loading: false })
      }
    })
  }

  render() {
    const { loading } = this.state
    return (
      <Layout>
        <SEO title="Login" keywords={[`vision care`]} description="Login" />

        <div className={"container"}>
          <div className="row">
            <div className="col-12">
              <h1 className={"page-title"}>Login</h1>
              {loading && (
                <div className="mt-5 pt-5">
                  <SpinnerLoading />
                </div>
              )}
              {!loading && (
                <div className={"row"}>
                  <div className={"col-12 col-md-6"}>
                    <form
                      onSubmit={this.handleSubmit}
                      method="post"
                      className="pb-5"
                    >
                      <div className="form-group">
                        {this.renderInput(
                          "username",
                          "Inserisci la tua email",
                          "text",
                          false,
                          "bootstrap"
                        )}
                      </div>

                      <div className="form-group">
                        {this.renderInput(
                          "password",
                          "Inserisci la tua password",
                          "password",
                          false,
                          "bootstrap"
                        )}
                      </div>

                      <div className="form-group">
                        {this.renderButton("Login", true)}
                      </div>

                      <ul>
                        <li className="my-0">
                          <a href="/recupero-password" className="text-orange">
                            Ho dimenticato la password
                          </a>
                        </li>

                        <li className="my-0">
                          <a href="/registrazione" className="text-orange">
                            Registrati come nuovo <strong>cliente</strong>
                          </a>
                        </li>

                        <li className="my-0">
                          <a
                            href="/registrazione-agente"
                            className="text-orange"
                          >
                            Registrati come nuovo <strong>agente</strong>
                          </a>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login
